import React from "react"
import "../components/layout.css"
import { Container, Buttons, Subhead, SearchBar } from "./../styles/elements.js"
import searchIcon from "./../svg/search.svg"

const SearchComponent = () => {
  return (
    <Container>
      <Subhead>Buttons</Subhead>
      <div className="row">
        <Buttons>Playlist</Buttons>
        <Buttons outline>Outlined</Buttons>
        <Buttons primary>Primary</Buttons>
        <Buttons secondary>Secondary</Buttons>
      </div>
      <Subhead>Search Bar</Subhead>
      <div className="row">
        <SearchBar>
          <img src={searchIcon} alt="search icon" />
          <input placeholder="Search your courses" />
        </SearchBar>
      </div>
    </Container>
  )
}

export default SearchComponent
